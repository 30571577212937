<template>
  <div id="questionSubmitView">
    <a-form :model="searchParams" layout="inline">
      <a-form-item field="questionId" label="题号" style="min-width: 320px">
        <a-input v-model="searchParams.questionId" placeholder="请输入名称" />
      </a-form-item>

      <a-form-item field="language" label="编程语言" style="min-width: 320px">
        <a-select
          v-model="searchParams.language"
          :style="{ width: '320px' }"
          placeholder="请选择语言"
        >
          <a-option>java</a-option>
          <a-option>cpp</a-option>
          <a-option>go</a-option>
        </a-select>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="doSubmit">搜索</a-button>
      </a-form-item>
    </a-form>
    <a-divider size="0" />
    <a-table
      :columns="columns"
      :data="dataList"
      :pagination="{
        showTotal: true,
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total,
      }"
      @page-change="onPageChange"
    >
      <!-- {"message":"成功","memoryLimit":"0","time":"106"}  -->
      <template #judgeInfo="{ record }">
        <!-- {{ JSON.stringify(record.judgeInfo) }} -->
        <a-space wrap>
          <a-tag :color="record.judgeInfo.message !== '成功' ? 'red' : 'green'"
            >{{
              record.judgeInfo.message
                ? record.judgeInfo.message !== "成功"
                  ? record.judgeInfo.message
                  : "成功"
                : "编译错误"
            }}
          </a-tag>
          <a-tag color="gold"
            >内存：{{
              !record.judgeInfo.memoryLimit ? 0 : record.judgeInfo.memoryLimit
            }}Mb
          </a-tag>
          <a-tag color="arcoblue"
            >时间：{{ !record.judgeInfo.time ? 0 : record.judgeInfo.time }}ms
          </a-tag>
        </a-space>
      </template>
      <template #createTime="{ record }">
        {{ moment(record.createTime).format("YYYY-MM-DD") }}
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import {
  Question,
  QuestionControllerService,
  QuestionQueryRequest,
  QuestionSubmitQueryRequest,
} from "@/question";
import message from "@arco-design/web-vue/es/message";
import { onMounted, ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import moment from "moment";
import store from "@/store";
import notification from "@arco-design/web-vue/es/notification";

const router = useRouter();

const dataList = ref([]);
const total = ref(0);
const searchParams = ref<QuestionSubmitQueryRequest>({
  current: 1,
  language: undefined,
  pageSize: 10,
  questionId: undefined,
});

const loadData = async () => {
  if (
    store.state.user.loginUser.userName === undefined ||
    store.state.user.loginUser.userName === "未登录"
  ) {
    notification.error({
      title: "提示",
      content: "请先登录",
    });
    return;
  }

  const res = await QuestionControllerService.listQuestionSubmitByPageUsingPost(
    { ...searchParams.value, sortField: "createTime", sortOrder: "descend" }
  );
  if (res.code === 0) {
    dataList.value = res.data.records;
    total.value = res.data.total;
  } else {
    message.error("加载失败," + res.message);
  }
};

const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

watchEffect(() => {
  loadData();
});

// onMounted(() => {
//   loadData();
// });

const toQuestionPage = (question: Question) => {
  router.push({
    path: `/view/question/${question.id}`,
  });
};

const doSubmit = () => {
  searchParams.value = {
    ...searchParams.value,
    current: 1,
  };
  //   loadData();
};

const columns = [
  {
    title: "提交号",
    dataIndex: "id",
  },
  {
    title: "编程语言",
    dataIndex: "language",
  },
  {
    title: "判题信息",
    slotName: "judgeInfo",
  },
  {
    title: "判题状态",
    dataIndex: "status",
  },
  {
    title: "题目id",
    dataIndex: "questionId",
  },
  {
    title: "提交者id",
    dataIndex: "userId",
  },
  {
    title: "创建时间",
    slotName: "createTime",
  },
];
</script>
<style scoped>
#questionSubmitView {
  max-width: 1280px;
  margin: 0 auto;
}
</style>
