<template>
  <div id="app">
    <template v-if="route.path.startsWith('/user')">
      <router-view />
    </template>
    <template v-else>
      <BasicLayout></BasicLayout>
    </template>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useRoute } from "vue-router";

import ACCESS_ENUM from "./access/accessEnum";
import BasicLayout from "./layouts/BasicLayout.vue";

const route = useRoute();
// const doInit = () => {
//   console.log("hello ");
// };

// onMounted(() => {
//   doInit();
// });
</script>
<style>
#webpack-dev-server-client-overlay {
  display: none !important;
}

/* https://blog.csdn.net/qq_58657388/article/details/135171245 */
</style>
