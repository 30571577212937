<template>
  <a-row id="globalHeader" class="grid-demo" :wrap="false">
    <a-col flex="auto">
      <a-menu
        mode="horizontal"
        :selected-keys="selectedKeys"
        @menu-item-click="doMenuClick"
      >
        <a-menu-item
          key="0"
          :style="{ padding: 0, marginRight: '38px' }"
          disabled
        >
          <div class="title-bar">
            <img class="logo" src="../assets/mylogo.svg" />
            <div class="title">OJ在线判题系统</div>
          </div>
        </a-menu-item>
        <a-menu-item v-for="item in visiableRoutes" :key="item.path">
          {{ item.name }}
        </a-menu-item>
      </a-menu>
    </a-col>
    <a-col flex="200px">
      <a-popconfirm :content="content" @ok="onOkClick">
        <a-avatar :size="64" style="font-weight: bold">{{
          store.state?.user?.loginUser.userName ?? "未登录"
        }}</a-avatar>
      </a-popconfirm>
    </a-col>
  </a-row>
</template>

<script lang="ts" setup>
import { routes } from "@/router/routes";
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import checkAccess from "@/access/checkAccess";
import ACCESS_ENUM from "@/access/accessEnum";

const router = useRouter();
const store = useStore();
const loginUser = ref(store.state.user.loginUser);

const content = ref();

const onOkClick = () => {
  if (
    loginUser.value.userName === "未登录" ||
    loginUser.value.userName === undefined
  ) {
    router.push({
      path: "/user/login",
    });
  } else {
    // console.log(loginUser.value.userName);
    // 清除登录信息
    store.dispatch("user/logout");
    // 重定向到主页
    router.push({
      path: "/",
    });
  }
  init();
};

const visiableRoutes = computed(() => {
  return routes.filter((item, index) => {
    if (item.meta?.hideInMenu) {
      return false;
    }

    if (
      !checkAccess(store.state.user.loginUser, item?.meta?.access as string)
    ) {
      return false;
    }
    return true;
  });
});

const selectedKeys = ref(["/"]);
router.afterEach((to, from, failure) => {
  selectedKeys.value = [to.path];
});
onMounted(() => {
  init();
});

const init = async () => {
  await store.dispatch("user/getLoginUser", {
    userName: "小鱼",
    userRole: ACCESS_ENUM.ADMIN,
  });
  loginUser.value = store.state.user.loginUser;
  // console.log(loginUser.value.userName);

  content.value =
    loginUser.value.userName === "未登录" ||
    loginUser.value.userName === undefined
      ? "去登录"
      : "你确定要注销吗？";
  // console.log(content.value);
};

// setTimeout(async () => {});

const doMenuClick = (key: string) => {
  router.push({
    path: key,
  });
};
</script>

<style scoped>
.title-bar {
  display: flex;
  align-items: center;
}
.title {
  color: #444;
  margin-left: 16px;
}
.logo {
  height: 48px;
}
</style>
