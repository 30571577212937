<template>
  <div id="viewQuestionView">
    <a-row :gutter="[24, 24]">
      <a-col :md="12" :xs="24">
        <a-tabs default-active-key="question">
          <a-tab-pane key="question" title="题目">
            <a-descriptions title="判题条件" :column="{ xs: 1, md: 2, lg: 3 }">
              <a-descriptions-item label="时间限制">
                <a-tag>{{ question?.judgeConfig?.timeLimit ?? 0 }}</a-tag>
              </a-descriptions-item>
              <a-descriptions-item label="内存限制">
                <a-tag>{{ question?.judgeConfig?.memoryLimit ?? 0 }}</a-tag>
              </a-descriptions-item>
              <a-descriptions-item label="堆栈限制">
                <a-tag>{{ question?.judgeConfig?.stackLimit ?? 0 }}</a-tag>
              </a-descriptions-item>
            </a-descriptions>
            <a-card v-if="question" :title="question.title">
              <template #extra>
                <a-space>
                  <a-tag
                    v-for="(tag, index) of question.tags"
                    :key="index"
                    color="green"
                  >
                    {{ tag }}
                  </a-tag>
                </a-space>
              </template>
              <MdViewer :value="question.content || ''" />
            </a-card>
          </a-tab-pane>
          <a-tab-pane key="comment" title="评论" disabled>
            评论页面
          </a-tab-pane>
          <a-tab-pane key="answer" title="答案"> 暂时无法查看答案 </a-tab-pane>
        </a-tabs>
      </a-col>
      <a-col :md="12" :xs="24">
        <a-form :model="form" layout="inline">
          <a-form-item
            field="language"
            label="编程语言"
            style="min-width: 320px"
          >
            <a-select
              v-model="form.language"
              :style="{ width: '320px' }"
              placeholder="请选择语言"
              @change="changeSelect"
            >
              <a-option>java</a-option>
              <a-option>cpp</a-option>
              <a-option>go</a-option>
            </a-select>
          </a-form-item>
        </a-form>
        <CodeEditor
          :language="form.language"
          :value="form.code as string"
          :handle-change="changeCode"
        />
        <a-divider size="0"></a-divider>
        <a-button type="primary" style="min-width: 180px" @click="doSubmit"
          >提交代码</a-button
        >
      </a-col>
    </a-row>
  </div>
</template>

<script setup lang="ts">
import {
  QuestionControllerService,
  QuestionSubmitAddRequest,
  QuestionVO,
} from "@/question";
import message from "@arco-design/web-vue/es/message";
import { onMounted, ref, defineProps, withDefaults } from "vue";
import CodeEditor from "@/components/CodeEditor.vue";
import MdViewer from "@/components/MdViewer.vue";
import { useRouter } from "vue-router";

interface Props {
  id: string;
}

const router = useRouter();
const props = withDefaults(defineProps<Props>(), {
  id: () => "",
});
const languageCode: { [key: string]: string } = {
  java: "import java.util.Scanner;\npublic class Main {\n    public static void main(String[] args) {\n        Scanner sc = new Scanner(System.in);\n        int a = sc.nextInt();\n        int b = sc.nextInt();\n        System.out.println((a + b));\n    }\n}",
  cpp: "#include <iostream>\nusing namespace std;\nint main() {\n    int a, b;\n    cin >> a >> b;\n    cout << (a + b) << endl;\n    return 0;\n}",
  go: 'package main\nimport "fmt"\nfunc main() {\n    var a, b int\n    fmt.Scanf("%d %d", &a, &b)\n    fmt.Println(a + b)\n}',
};
const question = ref<QuestionVO>();

const loadData = async () => {
  const res = await QuestionControllerService.getQuestionVoByIdUsingGet(
    props.id as any
  );
  if (res.code === 0) {
    question.value = res.data;
  } else {
    message.error("加载失败," + res.message);
  }
};

const form = ref<QuestionSubmitAddRequest>({
  language: "java",
  code: languageCode.java,
});

const changeSelect = (value: string) => {
  if (value !== "java") {
    message.warning(value + "语言暂时无法使用");
  }
};

const doSubmit = async () => {
  if (!question.value?.id) {
    return;
  }
  const res = await QuestionControllerService.doSubmitQuestionUsingPost({
    ...form.value,
    questionId: question.value.id,
  });
  if (res.code === 0) {
    message.success("提交成功");
    setTimeout(() => {
      router.push("/question_submit");
    }, 2000);
  } else {
    message.error("提交失败");
  }
};
const changeCode = (value: string) => {
  form.value.code = value;
};
onMounted(() => {
  loadData();
});
</script>
<style scoped>
#viewQuestionView {
  max-width: 1400px;
  margin: 0 auto;
}
</style>
