import ACCESS_ENUM from "@/access/accessEnum";
import { UserControllerService } from "@/question";
import { StoreOptions } from "vuex";

//actions调用mutation，action是异步的，mutation是同步的

export default {
  namespaced: true,
  state: () => ({
    loginUser: {
      userName: "未登录",
      // userRole: ACCESS_ENUM.NOT_LOGIN,
    },
  }),
  actions: {
    async getLoginUser({ commit, state }, payload) {
      //TODO 改为远程请求
      const res = await UserControllerService.getLoginUserUsingGet();
      if (res.code === 0) {
        // console.log(res.data);

        commit("updateUser", res.data);
      } else {
        commit("updateUser", {
          ...state.loginUser,
          userRole: ACCESS_ENUM.NOT_LOGIN,
        });
      }
    },
    async logout({ commit, state }) {
      // console.log("logout");

      //TODO 改为远程请求
      const res = await UserControllerService.userLogoutUsingPost();
      if (res.code === 0) {
        commit("updateUser", {});
        // console.log("logout success");
      }
    },
  },
  mutations: {
    updateUser(state, payload) {
      state.loginUser = payload;
    },
  },
} as StoreOptions<any>;
