<template>
  <div>
    <template v-if="flag">
      <div id="addQuestionView" class="add-question-container">
        <h2 class="add-question-title">创建题目</h2>
        <a-form :model="form" label-align="" class="add-question-form">
          <a-form-item field="title" label="标题" class="form-item">
            <a-input v-model="form.title" placeholder="请输入标题"></a-input>
          </a-form-item>
          <a-form-item field="title" label="标签" class="form-item">
            <a-input-tag
              v-model="form.tags"
              placeholder="请输入标签"
            ></a-input-tag>
          </a-form-item>

          <a-form-item field="content" label="题目内容" class="form-item">
            <MdEditor
              :value="form.content"
              :handle-change="onContentChange"
            ></MdEditor>
          </a-form-item>
          <a-form-item field="answer" label="答案" class="form-item">
            <MdEditor
              :value="form.answer"
              :handle-change="onAnswerChange"
            ></MdEditor>
          </a-form-item>

          <a-form-item
            label="判题配置"
            :content-flex="false"
            :merge-props="false"
          >
            <a-space direction="vertical" style="min-width: 480px">
              <a-form-item
                label="时间限制"
                field="judgeConfig.timeLimit"
                class="form-item"
              >
                <a-input-number
                  v-model="form.judgeConfig.timeLimit"
                  placeholder="请输入时间限制"
                  min="0"
                  size="large"
                  mode="button"
                />
              </a-form-item>
              <a-form-item
                label="内存限制"
                field="judgeConfig.memoryLimit"
                class="form-item"
              >
                <a-input-number
                  v-model="form.judgeConfig.memoryLimit"
                  placeholder="请输入内存限制"
                  size="large"
                  min="0"
                  mode="button"
                />
              </a-form-item>
              <a-form-item
                label="堆栈限制"
                field="judgeConfig.stackLimit"
                class="form-item"
              >
                <a-input-number
                  v-model="form.judgeConfig.stackLimit"
                  placeholder="请输入堆栈限制"
                  size="large"
                  min="0"
                  mode="button"
                />
              </a-form-item>
            </a-space>
          </a-form-item>
          <a-form-item
            label="测试用例"
            :content-flex="false"
            :merge-props="false"
          >
            <a-form-item
              v-for="(judgeCaseItem, index) of form.judgeCase"
              :key="index"
              no-style
            >
              <a-space direction="vertical" style="min-width: 480px">
                <a-form-item
                  :label="`输入用例-${index}`"
                  :field="`form.judgeCase[${index}].input`"
                  :key="index"
                >
                  <a-input
                    v-model="judgeCaseItem.input"
                    placeholder="请输入输入用例"
                  />
                </a-form-item>
                <a-form-item
                  :label="`输出用例-${index}`"
                  :field="`form.judgeCase[${index}].output`"
                  :key="index"
                >
                  <a-input
                    v-model="judgeCaseItem.output"
                    placeholder="请输入输出用例"
                  />
                </a-form-item>
                <a-button
                  status="danger"
                  @click="handleDelete(index)"
                  class="delete-button"
                >
                  删除
                </a-button>
              </a-space>
            </a-form-item>
            <div style="margin-top: 20px; max-width: 160px">
              <a-button
                type="outline"
                status="success"
                @click="handleAdd"
                class="add-case-button"
                >添加测试用例</a-button
              >
            </div>
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              @click="doSubmit"
              style="min-width: 150px"
              class="submit-button"
              >提交</a-button
            >
          </a-form-item>
        </a-form>
      </div>
    </template>
    <template v-else></template>
  </div>
</template>

<style scoped>
::v-deep .arco-form-item-label-col > .arco-form-item-label {
  max-width: 100%;
  color: var(--color-text-2);
  font-size: 14px;
  white-space: normal;
  font-weight: bold;
}

.add-question-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-question-title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.add-question-form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

.form-item {
  margin-bottom: 20px;
}

.add-case-button {
  width: 100%;
}

.delete-button {
  background-color: #ff4d4f;
  color: white;
}

.submit-button {
  background-color: #1890ff;
  color: white;
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
}

a-input,
a-input-tag,
a-input-number {
  width: 100%;
}

a-button[type="outline"] {
  border: 2px solid #52c41a;
  color: #52c41a;
}
</style>

<script setup lang="ts">
import MdEditor from "@/components/MdEditor.vue";
import { QuestionControllerService } from "@/question";
import store from "@/store";
import message from "@arco-design/web-vue/es/message";
import notification from "@arco-design/web-vue/es/notification";
import { onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
const route = useRoute();
const updatePage = route.path.includes("update");
const router = useRouter();
const loadData = async () => {
  if (
    store.state.user.loginUser.userName === undefined ||
    store.state.user.loginUser.userName === "未登录"
  ) {
    notification.error({
      title: "提示",
      content: "请先登录",
    });
    flag.value = false;
    return;
  }
  flag.value = true;
  const id = route.query.id;
  // console.log(id);

  if (!id) {
    return;
  }
  const res = await QuestionControllerService.getQuestionByIdUsingGet(
    id as any
  );
  if (res.code === 0) {
    form.value = res.data as any;

    if (!form.value.judgeCase) {
      form.value.judgeCase = [
        {
          input: "",
          output: "",
        },
      ];
    } else {
      form.value.judgeCase = JSON.parse(form.value.judgeCase as any);
    }
    if (!form.value.judgeConfig) {
      form.value.judgeConfig = {
        memoryLimit: 1000,
        stackLimit: 1000,
        timeLimit: 1000,
      };
    } else {
      form.value.judgeConfig = JSON.parse(form.value.judgeConfig as any);
    }
    if (!form.value.tags) {
      form.value.tags = [];
    } else {
      form.value.tags = JSON.parse(form.value.tags as any);
    }
  } else {
    message.error("加载失败," + res.message);
  }
};
const flag = ref(false);

onMounted(() => {
  loadData();
});

let form = ref({
  title: "",
  tags: [],
  content: "",
  answer: "",
  judgeConfig: {
    memoryLimit: 1000,
    stackLimit: 1000,
    timeLimit: 1000,
  },
  judgeCase: [
    {
      input: "",
      output: "",
    },
  ],
});

const handleAdd = () => {
  form.value.judgeCase.push({
    input: "",
    output: "",
  });
};
const handleDelete = (index: number) => {
  form.value.judgeCase.splice(index, 1);
};
const onContentChange = (value: string) => {
  form.value.content = value;
};
const onAnswerChange = (value: string) => {
  form.value.answer = value;
};

const doSubmit = async () => {
  // console.log(form);
  if (updatePage) {
    const res = await QuestionControllerService.updateQuestionUsingPost(
      form.value
    );
    if (res.code === 0) {
      message.success("更新成功");
      router.push("/questions");
    } else {
      message.error("更新失败：" + res.message);
    }
  } else {
    const res = await QuestionControllerService.addQuestionUsingPost(
      form.value
    );
    if (res.code === 0) {
      message.success("创建成功");
      router.push("/questions");
    } else {
      message.error("创建失败：" + res.message);
    }
  }
};
</script>
